import type { Notification } from '~/types/notification'

export const useNotificationStore = defineStore('notificationStore', () => {
  const notifications = ref<Notification[] | null>()
  const hasNotification = ref(false)

  return {
    notifications,
    hasNotification,
  }
})
