const { api } = useApi()

export default function useUser() {
  // Reusable composables
  const { user } = storeToRefs(useUserStore())
  const { hasNotification } = storeToRefs(useNotificationStore())
  const { logError } = useSentry()
  // const { MP_IdentifyUser, MP_SetUser } = useMixPanel()

  async function fetchUserInfo() {
    try {
      const response = await api.user.fetchUserInfo()
      if (response.success) {
        user.value = response.data
        hasNotification.value = response.data.isNotification
        localStorage.setItem('user', JSON.stringify(response.data))

        // MP_IdentifyUser(response.data.id)
        /* MP_SetUser({
          name: `${response.data.firstName} ${response.data.lastName}`,
          email: response.data.email,
          nickname: response.data.nickname,
        }) */
      }
    } catch (err) {
      logError(err)
    }
  }

  function logoutUser() {
    return navigateTo('/logout')
  }

  return {
    fetchUserInfo,
    logoutUser,
  }
}
